
export const RESTAURANTS_ITEM_COUNT_REQUEST = 8
export const REVIEWS_ITEM_COUNT_REQUEST = 5
export const COMMENTS_ITEM_COUNT_REQUEST = 5

export const USER = "user"
export const TOKEN = "token"

export const GOOGLE_CLIENT_ID = "327969055262-3h6a6tef0ucbgnhjadg4ftmdqfdlcbvb.apps.googleusercontent.com"

export const DEFAULT_PROFILE_IMAGE = "https://img.freepik.com/free-vector/man-profile-account-picture_24908-81754.jpg?w=740&t=st=1712969726~exp=1712970326~hmac=dc92e0ddc10f35fa5b84c5e4a1f0e307b26514c9a4b12751f1f8058c74e44222"



export enum AppRoutes {
    HOME = "",
    REVIEWS = "reviews",
}