import { AppRoutes } from "./constants";


export function path(route: AppRoutes): string {
    return `/${route}`;
}

export function getRateColor(rate: number): string {
    if (rate >= 4) {
        return '#4caf50'
    } else if (rate >= 3) {
        return 'yellow'
    } else {
        return 'red'
    }
} 