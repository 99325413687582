import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slice/auth_slice';
import replyingToReviewSlice from '../slice/repling_on_review_slice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        replingReview: replyingToReviewSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = (dispatch: AppDispatch, getState: () => RootState) => ReturnType;

export default store;
