import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './redux/store/store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { path } from './utils/utils';
import { AppRoutes, GOOGLE_CLIENT_ID } from './utils/constants';

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import ar from 'javascript-time-ago/locale/ar'
import './utils/i18n';
import i18n from './utils/i18n';

import 'react-toastify/dist/ReactToastify.css';

const Footer = React.lazy(() => import('./components/footer/footer'));
const ToastContainer = React.lazy(() => import('react-toastify').then(module => ({ default: module.ToastContainer })));
const HomeScreen = React.lazy(() => import('./screens/home_screen/home_screen'));
const ReviewsScreen = React.lazy(() => import('./screens/reviews_screen/reviews_screen'));
const GoogleOAuthProvider = React.lazy(() => import('@react-oauth/google').then(module => ({ default: module.GoogleOAuthProvider })));
const I18nextProvider = React.lazy(() => import('react-i18next').then(module => ({ default: module.I18nextProvider })));


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n} >
        <Suspense>
          <App />
        </Suspense>
        <ToastContainer />
      </I18nextProvider>
    </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);


function App(){

  TimeAgo.addDefaultLocale(en)
  TimeAgo.addLocale(en)
  TimeAgo.addLocale(ar)

  return (
    <html>
      <head>

      </head>
      <body>
        <Router>
          <Routes>
            <Route path={path(AppRoutes.HOME)} Component={HomeScreen} />
            <Route path={`${path(AppRoutes.REVIEWS)}/:id`} Component={ReviewsScreen} />
          </Routes>
        </Router  >
      </body>
      <footer>
      <Footer />

      </footer>
    </html>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
