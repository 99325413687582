import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../entities/user';
import store from '../store/store';


interface AuthState {
    isLoggedIn: boolean,
    user: User | null,
}

const initialState: AuthState = {
    isLoggedIn: false,
    user: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoggedIn(state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload;
        },
        setUser(state, action: PayloadAction<User | null>) {
            state.user = action.payload;
        },
    },
});

export const { setLoggedIn, setUser } = authSlice.actions;
export default authSlice.reducer;
export type AppDispatch = typeof store.dispatch;
