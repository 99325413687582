import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface ReplingState {
    reivewId: string;
}

const initialState: ReplingState = {
    reivewId: "",
};

const replyingToReviewSlice = createSlice({
    name: 'repling_on_review',
    initialState,
    reducers: {
        setReplingToReviewIdSlice(state, action: PayloadAction<string>) {
            state.reivewId = action.payload;
        },
    },
});



export const { setReplingToReviewIdSlice } = replyingToReviewSlice.actions;
export default replyingToReviewSlice.reducer;
